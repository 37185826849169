@use '@iq/react-components/dist/colors.scss' as colors;

.risk-modal-header {
  display: flex;
  gap: 0.5rem;
}

.risk-modal-body {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  min-height: 30rem;

  .risk-modal-left,
  .risk-modal-right {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .risk-modal-attribute {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;

      .attribute-value {
        word-break: break-all;
      }

      .attribute-label {
        font-weight: 500;
      }

      .description {
        min-width: 40rem;
        word-break: normal;
      }

      .risk-modal-source-event {
        cursor: pointer;
        color: #3b6bf6;
      }
    }
  }

  .risk-modal-left {
    width: calc(30% - 1rem);
  }

  .risk-modal-right {
    width: calc(70% - 1rem);
    min-width: 40rem;

    .risk-levels-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .risk-levels-label {
        font-weight: 500;
        display: flex;
        gap: 0.5rem;
      }

      .risk-levels {
        display: flex;
        gap: 2rem;

        .risk-level {
          display: flex;
          gap: 0.5rem;
          flex-direction: column;
          align-items: center;

          .risk-level-value {
            height: 70px;
            width: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            font-weight: 600;
          }
        }
      }

      .risk-levels-undertext {
        font-size: 0.8rem;
      }
    }
  }
}

.tooltip-box {
  max-width: 30rem;
  text-align: left;
}

.simple-modal-wrapper.risk-modal-wrapper {
  background-color: colors.$Grey0;
}

.dark-theme {
  #global-modal-container {
    .risk-modal-wrapper {
      background-color: colors.$Grey80;
    }
  }
}
