.layout-banner {
    background-color: #4779ed;
    color: white;
    padding: .5rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    border-radius: 4px;
    left: 0;
    margin :0.5% 0.5% 0 0.5%;
    width: 99%;
    z-index: 1000;
    opacity: 68%;
    // position: absolute;
    top: 4.6rem;
  }

  .icon-color{
    color: white;
   };
    
  
  .layout-banner-message {
    flex-grow: 1;
  }
  
  .layout-banner-button {
    background-color: transparent;
    color: white;
    border: 2px solid white;
    padding: 8px 15px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  
    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
  
  .layout-banner-message .message-content {
    display: flex;
    align-items: center;
  }
  
  .layout-banner-message .message-content p {
    margin: 0;
    margin-left: 10px;
  }