@use '@iq/react-components/dist/colors.scss' as colors;

.bim-extract-modal {
  width: 40rem;
  .error-text {
    color: colors.$StatusRed;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .message-text {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .json-editor {
    padding-top: 0.5rem;
    .rjsf {
      .form-group.field-boolean {
        margin-bottom: 0;
      }

      .message--container {
        height: 9rem;
        margin-left: 1.5rem;

        .bim-validation-message {
          height: 2.25rem;
        }

        .bim-message {
          margin-top: 0.5rem;
          display: flex;
        }

        .bim-message-header {
          width: 20rem;
        }
      }
    }
  }
}
