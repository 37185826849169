@use '@iq/react-components/dist/colors.scss' as colors;

.health-component-type {
  &.full-screen {
    .narrow,
    .wide,
    .widest {
      .grid-content {
        grid-template-columns: repeat(8, 1fr) !important;
        width: 75%;
      }
    }

    &.narrow.grid-content {
      width: 75%;
    }

    overflow: unset;
    .health-panel-component {
      overflow: visible;
      max-height: unset;
      min-height: 0;
      flex: 1;
    }

    .component-suggestion--wrapper {
      overflow: unset;
      overflow-y: auto;
      max-height: unset;
      flex: 1;
    }
  }

  .health-panel-component {
    width: 100%;
    max-height: 140vh;
    scrollbar-width: 7px;
    scrollbar-color: rgba(0, 0, 0, 0.25) rgba(0, 0, 0, 0);

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
      height: 7px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 7px;
      background-color: rgba(0, 0, 0, 0.25);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }

    &::-webkit-scrollbar-corner {
      background: rgba(0, 0, 0, 0);
    }

    &.empty {
      text-align: center;
      padding: 1rem;
    }

    .loading-container {
      height: auto;
    }

    .component-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      margin-bottom: 1.3rem;

      .component-name {
        font-size: 1.2rem;
        font-weight: 500;
      }
      .component-id {
        margin-top: 0.2rem;
      }

      .avt-react-components--icon {
        margin-right: 0.3rem;
      }
    }

    .component-status--wrapper {
      display: flex;
      align-items: center;

      border-radius: 0.2rem;
      padding: 0.6rem;
      color: colors.$Grey0;
      margin-bottom: 1.3rem;

      .component-status--icon {
        margin-right: 0.6rem;
      }
    }

    .component-status--empty {
      margin-bottom: 1.3rem;
      padding: 1rem;
      font-size: 1.1rem;
      text-align: center;
    }

    .component-suggestion--wrapper {
      max-height: calc(90vh - 8rem);
      overflow-y: auto;
      .component-suggestion--title {
        font-weight: 500;
      }

      .component-eventRiskStatus--list {
        background-color: rgb(221, 221, 221);
        padding: 0.063rem 0.625rem 0.625rem;
        margin-bottom: 1.3rem;
      }
      ul {
        margin-top: 0.5rem;
      }

      li {
        margin: 0.25rem 0 0.25rem 0.6rem;

        &::before {
          content: '•';
          margin-right: 0.6rem;
          font-size: 1.4rem;
        }
      }

      .component-suggestion--empty,
      .component-mitigation--empty {
        padding: 1rem;
        font-size: 1.1rem;
        text-align: center;
      }

      .component-status--wrapper {
        margin-top: 1.3rem;
      }
    }

    &.narrow {
      .grid-content {
        grid-template-columns: repeat(4, 1fr);
      }
    }

    &.wide,
    &.widest {
      .grid-content {
        grid-template-columns: repeat(8, 1fr);
      }
    }

    &.widest {
      .grid-content {
        width: 75%;
      }
    }

    /*Risk panel*/
    .styled-item {
      background: #f2f2f2;
      border: none;
      align-items: flex-start;

      &:hover {
        background: colors.$Grey10;
      }

      &.closed {
        padding-top: 5px;
        height: 100%;
      }

      @media only screen and (max-width: 1500px) {
        .grid {
          .grid-item {
            min-width: 70px;
          }

          .grid-content {
            grid-gap: 0;
          }
        }
      }

      &__header__label {
        min-width: 0;
        margin-right: 0rem;
        flex-direction: row;
        flex: 2;
        justify-content: space-between;

        .risk-status {
          padding-left: 4px;
          align-items: center;
        }
      }

      .styled-item__header {
        width: 100%;
        height: 100%;
        padding: 0 1rem;
        flex-wrap: wrap;

        &__actions {
          margin-bottom: 3.3rem;
        }

        .risk-content {
          width: 100%;
          display: flex;
          flex-direction: column;
          margin: 0.5rem 0;

          .risk-content-status,
          .risk-content-status > div {
            display: flex;
            justify-content: space-between;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-bottom: 2px;
          }

          .risk-title-info {
            display: flex;
            flex-direction: column;
            gap: 4px;
            overflow: hidden;
            text-wrap: nowrap;
            text-overflow: ellipsis;

            .risk-eventtext {
              font-weight: 500;
            }

            .risk-component {
              font-size: 0.9rem;
              color: colors.$Grey50;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }

    .grid {
      display: flex;
      flex-flow: column;

      .grid-item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        min-width: 90px;
        box-sizing: border-box;
      }
    }

    .filters-header {
      margin-bottom: 1rem;
    }
  }
}

@media only screen and (max-width: 1024px) and (max-width: 768px) {
  .health-panel-component.wide,
  .health-panel-component.widest {
    .grid-content.narrow {
      grid-template-columns: repeat(4, 1fr) !important;
    }
  }
}

@media only screen and (max-width: 768px) {
  .health-component-type {
    &.full-screen {
      .narrow {
        .grid-content {
          grid-template-columns: repeat(4, 1fr) !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .health-panel-component.wide {
    .grid-content.narrow {
      grid-template-columns: repeat(4, 1fr) !important;
    }
  }
}

@media only screen and (max-width: 460px) {
  .health-component-type .health-panel-component .styled-item__header__label {
    flex-direction: column !important;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.light-theme {
  .health-panel-component {
    &.empty {
      color: colors.$LightApplicationSecondaryText;
    }

    .component-id {
      color: colors.$LightApplicationSecondaryText;
    }

    .component-suggestion--empty,
    .component-mitigation--empty {
      color: colors.$LightApplicationSecondaryText;
    }

    .component-status--empty {
      color: colors.$LightApplicationSecondaryText;
    }
  }
}

.dark-theme {
  .health-panel-component {
    &.empty {
      color: colors.$DarkApplicationSecondaryText;
    }

    .component-id {
      color: colors.$DarkApplicationSecondaryText;
    }

    .component-suggestion--empty,
    .component-mitigation--empty {
      color: colors.$DarkApplicationSecondaryText;
    }

    .component-status--empty {
      color: colors.$DarkApplicationSecondaryText;
    }

    .risk-list-item.styled-item {
      background: #262626;
    }

    .styled-item .styled-item__body .risk-body {
      background: #333333;
    }
  }
}

.risk-body {
  padding-block: 5px;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.risk-downtime {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-weight: 500;
  flex-shrink: 0;

  .circle {
    width: 40px;
    height: 40px;
    line-height: 52px;
    border-radius: 50%;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.risk-filters {
  display: flex;
  gap: 0.5rem;
  &.wide {
    .select-period-component {
      .header {
        .label {
          display: block;
          text-wrap: nowrap;
        }

        .avt-react-components--icon {
          margin-left: 0.4rem;
        }
      }
    }
  }

  .select-period-component {
    .header {
      width: fit-content;

      .label {
        display: none;
      }

      .avt-react-components--icon {
        margin: 0;
      }
    }
    .content {
      right: unset;
    }
  }
  .avt-react-components--input {
    padding-block: 0;
  }

  .risk-filters-component {
    position: relative;

    .header {
      border-radius: 0.25rem;
      padding: 0.5rem 0.7rem;
      cursor: pointer;
      align-items: center;
      box-sizing: border-box;
      width: 7rem;
      display: flex;
      justify-content: space-between;
      border: 1px solid #cccccc;

      .icon-container {
        height: 100%;
        color: colors.$Grey30;
      }

      .label {
        display: flex;
        align-items: center;
        gap: 0.25rem;

        .active-filters-dot {
          fill: colors.$StatusBlue;
        }
      }
    }

    .form-group {
      .field {
        margin: 0;
      }

      #root_riskLevels {
        legend {
          font-size: 1rem;
          margin-bottom: 0.5rem;
        }
      }

      .filter-risk-level {
        display: flex;
        align-items: center;
        padding-bottom: 0.25rem;

        .checkbox {
          label {
            span {
              display: none;
            }
          }

          input {
            top: 0.1rem;
          }
        }

        label {
          margin-bottom: 0;
        }

        .risk-label {
          margin-left: 0.25rem;
          font-weight: 400;
        }
      }
    }

    .content {
      min-width: 20rem;
      z-index: 1000;
      box-sizing: border-box;
      display: absolute;
      white-space: nowrap;
      position: absolute;
      right: 0;
      bottom: 0;
      transform: translateY(100%);
      border-radius: 0.5rem;
      border: 1px solid colors.$Grey20;
      background-color: white;

      .body {
        display: flex;
        flex-direction: column;

        .risk {
          .risk-level-filters {
            display: flex;
            justify-content: space-between;
          }
          padding-left: 1rem;
          padding-top: 1rem;
          padding-inline: 0.75rem;

          .avt-react-components--button.design-text.activity-primary {
            &.theme-dark,
            &.theme-light {
              color: colors.$Grey50;
            }
          }

          .field-object {
            margin-bottom: 0;
          }
        }
      }

      @media screen and (max-width: 770px) {
        min-width: 12rem;
        width: 18rem;
      }

      .actions {
        display: flex;
        border-bottom: 1px solid colors.$Grey20;
        padding: 1rem;
        align-items: center;
        justify-content: space-between;

        .actions-button {
          display: flex;
          justify-content: space-between;
          gap: 0.75rem;
        }
      }
    }

    &.open {
      .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}

.base-view .panel {
  min-height: 0px;
}

.dark-theme .risk-filters-component {
  .header {
    background: colors.$DarkApplicationPanelBackground;
    color: colors.$DarkApplicationSecondaryText;
    border: 1px solid colors.$Grey70;
  }

  .content {
    background-color: colors.$DarkApplicationPanelBackground;
  }
}

.light-theme .risk-filters-component {
  .header {
    color: colors.$LightApplicationSecondaryText;
    border: 1px solid colors.$Grey20;
  }

  .content {
    background-color: colors.$LightApplicationPanelBackground;
  }
}
