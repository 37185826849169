@use '@iq/react-components/dist/colors.scss' as colors;
@use '../../../../../scss/_variables.scss' as vars;

.graph-visualization-component {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 0.5px solid transparent;

  &.checked {
    border-color: colors.$Grey20;
    border-radius: 12px;
  }
  .graph-header {
    position: relative;
    min-height: 2.5rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header-title {
      display: flex;
      align-items: center;
      padding-left: 0.5rem;

      .title {
        margin-left: 0.5rem;
        font-size: 1rem;
        font-weight: 600;
      }

      .select {
        &.checked {
          color: colors.$StatusBlue;
        }
      }
    }

    .viz-loading-spinner {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    .menu {
      padding-right: 0.5rem;

      .spinner {
        position: absolute;
        right: 2rem;
        top: 50%;
        transform: translateY(-50%);
      }

      .content {
        max-width: 13rem;
        z-index: 10;
        box-sizing: border-box;
        position: absolute;
        display: none;
        white-space: nowrap;
        background: white;
        right: 10px;
        top: calc(100% + 0.1rem);
        border-radius: 0.25rem;
        border: 1px solid;

        .title {
          padding: 0.75rem;
          padding-bottom: 0.5rem;
          font-weight: 500;
        }

        .choices {
          padding: 1rem 0;

          .choice {
            font-weight: 500;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 0.7rem 1rem;
            display: flex;
            align-items: center;
            cursor: pointer;

            .avt-react-components--icon {
              margin-right: 10px;
            }
          }
        }
      }

      &.open {
        .content {
          display: block;
        }
      }
    }
  }

  .graph-wrapper {
    position: relative;

    .no-data {
      position: absolute;
      left: 50%;
      top: 30%;
      transform: translate(-50%, -50%);
      opacity: 0.75;
    }
  }

  .recharts-label,
  .recharts-legend-item {
    font-size: 0.75rem;
  }

  .recharts-surface {
    overflow: visible;
  }

  .recharts-default-tooltip {
    border-radius: 0.2rem;
    border-color: colors.$Grey10 !important;
  }
}

.graph-legend {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  .graph-legend-item {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin: 0 0.5rem;
    line-height: 1.3rem;

    svg {
      margin-right: 0.4rem;
    }
  }
}

.dark-theme .graph-visualization-component {
  .graph-header .menu .content {
    background-color: colors.$DarkApplicationPanelBackground;
    box-shadow: vars.$BoxShadowDark;
    border-color: colors.$Grey80;

    .title {
      color: colors.$DarkApplicationSecondaryText;
    }

    .choice {
      &:hover,
      &.selected {
        background: colors.$Grey80;
      }
    }
  }

  .recharts-label tspan {
    fill: colors.$DarkApplicationPrimaryText;
  }

  .recharts-default-tooltip {
    background-color: colors.$Grey85 !important;
    border: none !important;
  }

  .recharts-cartesian-grid line {
    stroke: colors.$Grey80;
  }
}

.light-theme .graph-visualization-component {
  .recharts-label tspan {
    fill: colors.$LightApplicationPrimaryText;
  }

  .recharts-cartesian-grid line {
    stroke: colors.$Grey20;
  }

  .header-title {
    .title {
      color: colors.$LightApplicationSecondaryText ;
    }
  }

  .graph-header .menu .content {
    background-color: colors.$LightApplicationPanelBackground;
    box-shadow: vars.$BoxShadowLight;
    border-color: colors.$Grey10;

    .title {
      color: colors.$LightApplicationSecondaryText ;
    }

    .choice {
      &:hover,
      &.selected {
        background: colors.$Grey10;
      }
    }
  }
}
