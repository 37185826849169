@use '@iq/react-components/dist/colors.scss' as colors;

.permissions-header {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1rem 0 1rem;

  > div {
    flex: 0 0 28%;
  }

  > h4 {
    flex: 0 0 18%;
    display: flex;
    justify-content: center;
  }
}

.dash-indicator {
  position: relative;
  top: -4px;
  right: 53px;
  width: 0px;
  font-size: 35px;
}

.dash-indicator,
.disabled-permission {
  cursor: not-allowed;
}

.permission-checkbox-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 1rem;
  align-items: center;

  > div {
    flex: 0 0 18%;
  }

  .permission-label {
    flex: 0 0 28%;
    text-transform: capitalize;
  }

  .avt-react-components--icon {
    cursor: pointer;
  }

  .permission-checkbox-checked {
    color: colors.$StatusBlue;
  }
}

.light-theme {
  .permissions-header,
  .custom-role-form {
    background: colors.$LightApplicationPanelBackground;
  }
}

.dark-theme {
  .permissions-header,
  .custom-role-form {
    background: colors.$DarkApplicationPanelBackground;
  }
}
