@use '@iq/react-components/dist/colors.scss' as colors;
@use '../../../../../scss/_variables.scss' as vars;

.pie-visualization-component {
  width: 100%;
  height: 100%;

  .pie-header {
    position: relative;
    margin-bottom: 1rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 2.5rem;

    .title {
      font-size: 1rem;
      font-weight: 600;
    }

    .spinner {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    .menu {
      padding-right: 0.5rem;

      .spinner {
        position: absolute;
        right: 2rem;
        top: 50%;
        transform: translateY(-50%);
      }

      .content {
        max-width: 13rem;
        z-index: 10;
        box-sizing: border-box;
        position: absolute;
        display: none;
        white-space: nowrap;
        background: white;
        right: 10px;
        top: calc(100% + 0.1rem);
        border-radius: 0.25rem;
        border: 1px solid;

        .title {
          padding: 0.75rem;
          padding-bottom: 0.5rem;
          font-weight: 500;
        }

        .choices {
          padding: 1rem 0;

          .choice {
            font-weight: 500;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 0.7rem 1rem;
            display: flex;
            align-items: center;
            cursor: pointer;

            .avt-react-components--icon {
              margin-right: 10px;
            }
          }
        }
      }

      &.open {
        .content {
          display: block;
        }
      }
    }
  }

  .pie-wrapper {
    position: relative;
  }

  .recharts-surface {
    overflow: visible;
  }

  .recharts-default-tooltip {
    border-radius: 0.2rem;
    border-color: colors.$Grey10 !important;
  }

  .pie-legend {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .pie-legend-item {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin: 0 0.5rem;
      line-height: 1.3rem;

      svg {
        margin-right: 0.4rem;
      }
    }
  }
  .recharts-tooltip-label {
    margin: 0 !important;
  }
}

.dark-theme .pie-visualization-component {
  .pie-header .menu .content {
    background-color: colors.$DarkApplicationPanelBackground;
    box-shadow: vars.$BoxShadowDark;
    border-color: colors.$Grey80;

    .title {
      color: colors.$DarkApplicationSecondaryText;
    }

    .choice {
      &:hover,
      &.selected {
        background: colors.$Grey80;
      }
    }
  }

  .recharts-default-tooltip {
    background-color: colors.$Grey85 !important;
    border: none !important;
  }

  .recharts-tooltip-item {
    color: #e6e6e6 !important;
  }
}

.light-theme .pie-visualization-component {
  .title {
    color: colors.$LightApplicationSecondaryText;
  }

  .pie-header .menu .content {
    background-color: colors.$LightApplicationPanelBackground;
    box-shadow: vars.$BoxShadowLight;
    border-color: colors.$Grey10;

    .choice {
      &:hover,
      &.selected {
        background: colors.$Grey10;
      }
    }
  }
}
