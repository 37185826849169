@use '@iq/react-components/dist/colors.scss' as colors;

.layout-view {
  padding-bottom: 1rem;

  .site-configuration-editor {
    padding: 1rem;
  }

  &.updated {
    padding-bottom: 8rem;
  }

  .panel-updated-notice {
    padding: 2rem;
    margin: 1rem;
    border-radius: 0.25rem;
    z-index: 10;
    width: calc(100% - 8rem);
    bottom: 0px;
    position: fixed;
    animation: slideUp 0.5s;

    .notice {
      text-align: center;
    }

    .actions {
      display: flex;
      margin-top: 1rem;
      justify-content: center;

      .avt-react-components--button {
        margin: 0 0.5rem;
      }
    }
  }

  .file-import {
    position: relative;
  }
}

.dark-theme {
  .layout-view {
    .panel-updated-notice {
      background: colors.$DarkApplicationPanelBackground;
    }
  }

  .json-editor-component {
    .prop-table .row,
    .key,
    .value {
      border: 1px solid colors.$Grey95;
    }
  }

  .rjsf {
    color: colors.$DarkApplicationPrimaryText;
    padding: 1rem;

    legend {
      color: colors.$DarkApplicationPrimaryText;
      border-bottom-color: colors.$DarkApplicationBorder;
    }
  }
}

.light-theme {
  .layout-view {
    .panel-updated-notice {
      background: colors.$LightApplicationPanelBackground;
      border: 1px solid colors.$LightApplicationBorder;
    }
  }

  .json-editor-component {
    .prop-table .row,
    .key,
    .value {
      border: 1px solid colors.$Grey10;
    }
  }
}

@keyframes slideUp {
  from {
    bottom: -20px;
  }
  to {
    bottom: 0px;
  }
}
