@use '@iq/react-components/dist/colors.scss' as colors;
@use '../../../../../scss/_variables.scss' as vars;

.duval-visualization-component {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 0.5px solid transparent;

  &.checked {
    border-color: colors.$Grey20;
    border-radius: 12px;
  }
  .duval-preview-title {
    font-weight: 500;
  }
  .duval-header {
    position: relative;
    min-height: 2.5rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header-title {
      display: flex;
      align-items: center;
      padding-left: 0.5rem;

      .title {
        margin-left: 0.5rem;
        font-size: 1rem;
        font-weight: 600;
      }

      .select {
        &.checked {
          color: colors.$StatusBlue;
        }
      }
    }

    .spinner {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    .menu {
      .content {
        max-width: 13rem;
        z-index: 10;
        box-sizing: border-box;
        position: absolute;
        display: none;
        white-space: nowrap;
        background: white;
        right: 10px;
        top: calc(100% + 0.1rem);
        border-radius: 0.25rem;
        border: 1px solid;

        .title {
          padding: 0.75rem;
          padding-bottom: 0.5rem;
          font-weight: 500;
        }

        .choices {
          padding: 1rem 0;

          .choice {
            font-weight: 500;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 0.7rem 1rem;
            display: flex;
            align-items: center;
            cursor: pointer;

            .avt-react-components--icon {
              margin-right: 10px;
            }
          }
        }
      }

      &.open {
        .content {
          display: block;
        }
      }
    }
  }
  .duval-point:hover {
    z-index: 100;
  }

  .duval-wrapper {
    position: relative;
    height: 100%;
  }

  .duval-surface {
    overflow: visible;
  }

  .duval-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}

.duval-error-container {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  opacity: 0.75;
}

.area-lines,
.tick-lines {
  pointer-events: none;
}

.area-lines-stroke {
  stroke: black;
}

.area-highlighs {
  pointer-events: none;
}

.duval-legend {
  display: flex;
  gap: 0rem 1rem;
  max-width: 400px;
  max-height: 4rem;
  flex-wrap: wrap;
  justify-content: center;
  padding-block: 0.5rem;
  text-align: center;
}

.tooltip {
  padding-block: 10px;
  padding-inline: 10px;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 1000;
  border-radius: 0.2rem;
  padding: 10px;

  .tooltip-time {
    margin-bottom: 0.5rem;
  }
  .tooltip-item {
    padding-block: 4px;
  }

  th,
  td {
    border-left: 1px solid black;
    border-right: 1px solid black;
    padding: 0.5rem;
  }
  tr {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
  }
}

.visualization-fullscreen {
  .duval-legend {
    padding-block: 1.5rem;
  }
}

.dark-theme .duval-visualization-component {
  .duval-header .menu .content {
    background-color: colors.$DarkApplicationPanelBackground;
    box-shadow: vars.$BoxShadowDark;
    border-color: colors.$Grey80;

    .title {
      color: colors.$DarkApplicationSecondaryText;
    }

    .choice {
      &:hover,
      &.selected {
        background: colors.$Grey80;
      }
    }
  }

  .area-highlights {
    opacity: 0.6;
  }

  .tooltip {
    background-color: #262626 !important;
    border: none !important;
  }

  .axis-label {
    fill: colors.$Grey20;
  }

  .triangle-side,
  .axis-label-line,
  .tick-line,
  .point-in-area-stroke,
  .point-lines {
    stroke: colors.$Grey20;
  }
  .area-lines-stroke {
    stroke: colors.$Grey30;
  }

  .axis-label-arrow {
    stroke: colors.$Grey20;
    fill: colors.$Grey20;
  }
  .area {
    filter: brightness(0.7) saturate(2.2);
  }
}

.light-theme .duval-visualization-component {
  .tooltip {
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(204, 204, 204);
  }

  .duval-header{
    .title {
      color: colors.$LightApplicationSecondaryText;
    }
  }

  .duval-header .menu .content {
    background-color: colors.$LightApplicationPanelBackground;
    box-shadow: vars.$BoxShadowLight;
    border-color: colors.$Grey10;

    .choice {
      &:hover,
      &.selected {
        background: colors.$Grey10;
      }
    }
  }

  .axis-label {
    fill: black;
  }

  .triangle-side,
  .area-lines-stroke,
  .axis-label-line,
  .tick-line,
  .point-in-area-stroke,
  .point-lines,
  .axis-label-arrow {
    stroke: black;
  }
}
