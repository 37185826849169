@use '@iq/react-components/dist/colors.scss' as colors;
@use '../../../../../scss/_variables.scss' as vars;

.star-visualization-component {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 0.5px solid transparent;

  .star-border {
    stroke: colors.$Grey20;
  }

  &.checked {
    border-color: colors.$Grey20;
    border-radius: 12px;
  }
  .star-preview-title {
    font-weight: 500;
  }
  .star-header {
    position: relative;
    min-height: 2.5rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header-title {
      display: flex;
      align-items: center;
      padding-left: 0.5rem;

      .title {
        margin-left: 0.5rem;
        font-size: 1rem;
        font-weight: 600;
      }

      .select {
        &.checked {
          color: colors.$StatusBlue;
        }
      }
    }

    .spinner {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    .menu {
      .content {
        max-width: 13rem;
        z-index: 10;
        box-sizing: border-box;
        position: absolute;
        display: none;
        white-space: nowrap;
        background: white;
        right: 10px;
        top: calc(100% + 0.1rem);
        border-radius: 0.25rem;
        border: 1px solid;

        .title {
          padding: 0.75rem;
          padding-bottom: 0.5rem;
          font-weight: 500;
        }

        .choices {
          padding: 1rem 0;

          .choice {
            font-weight: 500;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 0.7rem 1rem;
            display: flex;
            align-items: center;
            cursor: pointer;

            .avt-react-components--icon {
              margin-right: 10px;
            }
          }
        }
      }

      &.open {
        .content {
          display: block;
        }
      }
    }
  }

  .star-wrapper {
    position: relative;

    .star-container {
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }
  }

  .grid-axis-line {
    stroke: colors.$Grey60;
    stroke-dasharray: 4 4;
    stroke-width: 1;
  }
  .grid-tick {
    stroke-width: 1;
    stroke: colors.$Grey60;
  }
  .grid-axis {
    stroke-width: 1;
    stroke-dasharray: 3 3;
  }
  .grid-radius {
    fill: none;
    stroke-width: 1;
    stroke-dasharray: 3 3;
  }
}

.star-error-container {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  opacity: 0.75;
}
.star-info-container {
  width: 100%;
  text-align: center;
  opacity: 0.75;
}

.point:hover {
  stroke: black;
}

.tooltip {
  padding-block: 10px;
  padding-inline: 10px;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 1000;
  border-radius: 0.2rem;
  padding: 10px;

  .tooltip-time {
    margin-bottom: 0.5rem;
  }
  .tooltip-item {
    padding-block: 4px;
  }
}

.star-legend {
  display: flex;
  gap: 0rem 1rem;
  max-width: 400px;
  flex-wrap: wrap;
  justify-content: center;
  padding-block: 0.5rem;

  .star-legend-item {
    display: flex;
    align-items: center;
  }

  .star-legend-axis {
    stroke-dasharray: 5 3;
    stroke-width: 2;
  }
}

.star-grid-text {
  stroke: none;
  font-size: 11px;
  fill: colors.$Grey60;
}

.dark-theme .star-visualization-component {
  .star-border {
    stroke: colors.$Grey50;
  }

  .star-header .menu .content {
    background-color: colors.$DarkApplicationPanelBackground;
    box-shadow: vars.$BoxShadowDark;
    border-color: colors.$Grey80;

    .title {
      color: colors.$DarkApplicationSecondaryText;
    }

    .choice {
      &:hover,
      &.selected {
        background: colors.$Grey80;
      }
    }
  }

  .tooltip {
    background-color: colors.$Grey85 !important;
    border: none !important;
  }

  .star-grid {
    stroke: colors.$Grey80;
  }
}

.light-theme .star-visualization-component {
  .star-grid {
    stroke: colors.$Grey20;
  }

  .tooltip {
    background-color: colors.$Grey0;
    border: 1px solid colors.$Grey10;
  }
  .star-header{
    .title {
      color: colors.$LightApplicationSecondaryText;
    }
  }

  .star-header .menu .content {
    background-color: colors.$LightApplicationPanelBackground;
    box-shadow: vars.$BoxShadowLight;
    border-color: colors.$Grey10;

    .title {
      color: colors.$LightApplicationSecondaryText;
    }

    .choice {
      &:hover,
      &.selected {
        background: colors.$Grey10;
      }
    }
  }
}
