@use '@iq/react-components/dist/colors.scss' as colors;

.docs-component {
  .item-group {
    flex-wrap: wrap;
  }
}

.docs-spinner {
  display: flex;
  justify-content: center;
  padding: 10rem;
}

.back-link {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 700;
  padding: 2rem 1.5rem 0rem;
  cursor: pointer;
  a {
    color: colors.$Grey70;
    text-decoration: none;
  }

  a:visited {
    text-decoration: none;
  }
}
