.corevisualization-component {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: flex-start;

  .viz-loading-spinner {
    margin-right: 1.5rem;
  }

  &.visualization-fullscreen {
    padding-bottom: 1rem;
    height: calc(100vh - 16rem);
  }
}

.height-medium-small,
.height-small {
  .metric-visualization-component {
    .wrapper .value-wrapper .value {
      font-size: 1.4rem;
    }

    .wrapper {
      .label {
        top: 0.5rem;
      }
    }

    .value-wrapper {
      line-height: 1.6rem;
    }
  }
  .gauge-visualization-component {
    .gauge-wrapper .value-wrapper .value-wrapper--value .value {
      font-size: 1.4rem;
    }
  }
}
