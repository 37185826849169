@use '@iq/react-components/dist/colors.scss' as colors;
@use '../../../scss/_variables.scss' as vars;

.select-period-component {
  position: relative;

  .header {
    border-radius: 0.25rem;
    padding: 0.5rem 0.7rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 16rem;
    justify-content: space-between;

    .avt-react-components--icon:first-of-type {
      margin-right: 0.4rem;
    }

    .avt-react-components--icon:last-of-type {
      margin-left: 0.4rem;
    }
  }

  .absolute-time-picker {
    .SingleDatePicker {
      .SingleDatePicker_picker {
        top: 0 !important;
        left: -330px !important;
        margin-top: 0;
        box-shadow: vars.$BoxShadowLight;

        @media screen and (max-width: 1024px) {
          left: -329px !important;
        }

        @media screen and (max-width: 750px) {
          display: none;
        }
      }
    }

    &.calendar-right {
      .SingleDatePicker .SingleDatePicker_picker {
        left: 140px !important;
      }
    }

    .datetime-row {
      flex-direction: row;
      display: flex;
      margin: 5px 0 10px;

      > div:first-of-type {
        margin-right: 10px;
      }
    }

    .submit {
      margin-top: 2px;
      display: flex;
      justify-content: flex-end;
    }
  }

  .content {
    min-width: 20rem;
    z-index: 2002;
    box-sizing: border-box;
    position: absolute;
    display: none;
    white-space: nowrap;
    right: 0;
    bottom: 0;
    transform: translateY(100%);
    border-radius: 0.25rem;
    padding-bottom: 1rem;

    @media screen and (max-width: 770px) {
      min-width: 12rem;
      width: 18rem;
    }

    .actions {
      display: flex;
      margin: 1rem 1rem 0;
      align-items: center;
      justify-content: flex-end;
      gap: 0.75rem;
      .actions-checkbox {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: auto;

        > p {
          margin-left: 0.5rem;
        }
      }
    }

    .title {
      padding: 0 0.75rem 0.5rem;
      font-weight: 500;
    }

    .absolute {
      border-right: 1px solid transparent;

      .absolute-time-picker {
        display: flex;
        flex-direction: column;
        padding: 0.5rem 0.75rem;

        @media screen and (max-width: 770px) {
          max-width: 7rem;
        }

        .absolute-label {
          padding-bottom: 0.5rem;
        }
        .absolute-date-picker {
          padding-bottom: 0.75rem;
        }
      }
    }

    .choices {
      .choice {
        padding: 0.5rem 0.75rem;
        cursor: pointer;
        width: 100%;
        box-sizing: border-box;
      }
    }
  }

  &.open {
    .content {
      display: flex;
      flex-direction: column;
    }
  }
}

.dark-theme .select-period-component {
  .header {
    background: colors.$DarkApplicationPanelBackground;
    color: colors.$DarkApplicationPrimaryText;
    border: 1px solid colors.$Grey70;
  }

  .content {
    background-color: colors.$DarkApplicationPanelBackground;
    box-shadow: vars.$BoxShadowDark;

    .title {
      color: colors.$DarkApplicationSecondaryText;
    }

    .datetime-row .time-picker__time {
      color: colors.$DarkApplicationPrimaryText;
    }

    .absolute-time-picker {
      .DateRangePickerInput {
        background-color: colors.$DarkApplicationPanelBackground;

        .DateInput {
          input {
            border: 1px solid colors.$Grey70;

            &:focus,
            &:active {
              border-color: colors.$StatusBlue;
            }
          }
        }
        .DateRangePickerInput_arrow {
          color: #e6e6e6;
        }
      }
    }

    .absolute {
      border-color: colors.$DarkApplicationBorder;
    }

    .choices {
      .choice {
        &:hover,
        &.selected {
          background: colors.$Grey80;
        }
      }
    }
  }
}

.light-theme .select-period-component {
  .header {
    color: colors.$LightApplicationPrimaryText;
    border: 1px solid colors.$Grey20;
  }

  .datetime-row .time-picker__time {
    color: colors.$LightApplicationPrimaryText;
  }

  .content {
    background-color: colors.$LightApplicationPanelBackground;
    box-shadow: vars.$BoxShadowLight;

    .title {
      color: colors.$LightApplicationPrimaryText;
    }

    .absolute {
      border-color: colors.$LightApplicationBorder;
    }

    .choices {
      .choice {
        &:hover,
        &.selected {
          background: colors.$Grey10;
        }
      }
    }
  }
}
