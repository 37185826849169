@use '@iq/react-components/dist/colors.scss' as colors;

.visualization-modal-menu {
  bottom: 2px;
  right: 1rem;
}

.simple-modal-wrapper {
  &.visualization-fullscreen-modal {
    background-color: colors.$Grey0;
  }
}

.dark-theme {
  &.visualization-fullscreen-modal {
    background-color: colors.$Grey80;
  }
}
