@use '@iq/react-components/dist/colors.scss' as colors;
@use '../../../../../scss/_variables.scss' as vars;

.gauge-visualization-component {
  width: 100%;
  height: 100%;
  overflow: hidden;

  &.invalid-data {
    .gauge-title {
      display: inline-flex;

      svg {
        margin-top: -2px;
        margin-left: 2px;
      }
    }

    .gauge-wrapper {
      opacity: 0.2;
    }
  }

  .menu {
    padding-right: 0.5rem;
    margin-left: auto;
    .spinner {
      position: absolute;
      right: 2rem;
      top: 50%;
      transform: translateY(-50%);
    }

    .content {
      max-width: 13rem;
      z-index: 10;
      box-sizing: border-box;
      position: absolute;
      display: none;
      white-space: nowrap;
      background: white;
      right: 10px;
      top: calc(100% + 0.1rem);
      border-radius: 0.25rem;
      border: 1px solid;

      .title {
        padding: 0.75rem;
        padding-bottom: 0.5rem;
        font-weight: 500;
      }

      .choices {
        padding: 1rem 0;

        .choice {
          font-weight: 500;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 0.7rem 1rem;
          display: flex;
          align-items: center;
          cursor: pointer;

          .avt-react-components--icon {
            margin-right: 10px;
          }
        }
      }
    }

    &.open {
      .content {
        display: block;
      }
    }
  }

  .gauge-header {
    position: relative;
    margin-bottom: 1rem;
    font-weight: 500;
    display: flex;

    .title{
      font-size: 1rem;
      font-weight: 600;
    }

    .viz-loading-spinner {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .gauge-wrapper {
    position: relative;
    transform: translateY(12%);

    .value-wrapper {
      font-weight: 600;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 49.5%; // recharts responsive container sets width to 99%
      transform: translate(-50%, -50%);

      &--value {
        display: flex;
        align-items: flex-end;

        .value {
          font-size: 1.8rem;
        }

        .unit {
          display: inline-block;
          font-size: 0.9rem;
          margin-left: 0.2rem;
        }
      }
    }
  }

  .gauge-value-marker:last-child {
    display: none;
  }

  .recharts-surface {
    overflow: visible;
  }
}

.light-theme .gauge-visualization-component {
  .recharts-bar-background-rectangle {
    fill: colors.$Grey10;
  }
  .title {
    color: colors.$LightApplicationSecondaryText;
  }

  .gauge-value-marker {
    > polygon {
      fill: black;
    }
  }

  .gauge-header .menu .content {
    background-color: colors.$LightApplicationPanelBackground;
    box-shadow: vars.$BoxShadowLight;
    border-color: colors.$Grey10;

    .title {
      color: colors.$LightApplicationSecondaryText;
    }

    .choice {
      &:hover,
      &.selected {
        background: colors.$Grey10;
      }
    }
  }
}

.dark-theme .gauge-visualization-component {
  .recharts-bar-background-rectangle {
    fill: colors.$Grey80;
  }
  .gauge-value-marker {
    > polygon {
      fill: colors.$Grey0;
    }
  }

  .gauge-header .menu .content {
    background-color: colors.$DarkApplicationPanelBackground;
    box-shadow: vars.$BoxShadowDark;
    border-color: colors.$Grey80;

    .title {
      color: colors.$DarkApplicationSecondaryText;
    }

    .choice {
      &:hover,
      &.selected {
        background: colors.$Grey80;
      }
    }
  }
}

#global-modal-container {
  .invalid-data {
    .gauge-header {
      button {
        display: none;
      }
    }
    .gauge-wrapper {
      opacity: 1;
    }
  }
}
